
// import './utils/sentry'
import Listeners from "./Listeners";
import beforeUnloadListener from './beforeUnloadListener';
import Cookies from 'js-cookie';
import {makeID} from "./utils/utils";
// import * as Sentry from "@sentry/browser";
import {Buffer} from "buffer";

export let loggedInUser;
export let usermeta: any = {};
export let currentTradingHouse: {
    name: string,
    domains: string[],
    routes: {
        greenchart: string,
        support: string,
        login: string,
        betaURL: string
    }
}

const urlParams = new URLSearchParams(window.location.search);
let reauth: boolean = urlParams.get('reauth') === 'true';
let authToken: string = urlParams.get('authToken') || Cookies.get('authToken');
export const sharedLayoutID = urlParams.get('sharedLayoutID');
export const layoutID = urlParams.get('layout');
export const shouldRedirectToAccount = urlParams.get('marketplace')
export const marketplaceDisplayProduct = urlParams.get('display')
export let isBeta = false;
const domains = location.hostname.split('.')
Cookies.remove('authToken', {path: '/', domain: `.${domains[domains.length-2]}.${domains[domains.length-1]}`})

// @ts-ignore
const myAccountURl = __MY_ACCOUNT_URL__

function preserveQueryParams() {
    let queryParams = '';
    if (sharedLayoutID) {
        queryParams += `${queryParams.length ? '&' : '?'}sharedLayoutID=${sharedLayoutID}`
    }
    if (layoutID) {
        queryParams += `${queryParams.length ? '&' : '?'}layout=${layoutID}`
    }
    if (shouldRedirectToAccount) {
        queryParams += `${queryParams.length ? '&' : '?'}marketplace=true`
        if (marketplaceDisplayProduct) {
            queryParams += `${queryParams.length ? '&' : '?'}display=${marketplaceDisplayProduct}`
        }
    }
    return queryParams
}

export default {

    async init(callback, roles?) {

        const {firebase_auth, firebase_functions, firestore_db} = await import('./utils/gcFirebase');
        const {doc, getDoc, onSnapshot, collection, query, where, limit, getDocs} = await import('firebase/firestore');
        const {httpsCallable} = await import('firebase/functions');
        const {onAuthStateChanged, signInWithCustomToken, signOut} = await import('firebase/auth');
        const {evaluatePermissions} = await import("./GC_Permissions")


        if (!hasBrowserStorage()) {
            window.location.href = '/invalidCookies.html'
            return
        }

        let usermetaSubID = null


        initAuthentication();

        function hasBrowserStorage() {
            try {
                let storage = window.sessionStorage;
                storage.setItem('test_session_storage', 'test_session_storage');
                storage.removeItem('test_session_storage');
                return true
            } catch (e) {
                return false
            }
        }

        async function signIn() {
            if (authToken) {
                sessionStorage.clear();
                signInWithCustomToken(firebase_auth, authToken)
                    .then((cred) => localStorage.setItem('gcid', cred.user.uid))
                    .catch(() => window.location.href = "/contactSupport.html")
            }
            authToken = null
        }

        function listenToAccountStatus (gcid) {

            if (usermetaSubID) {
                Listeners.unsubscribeSnapshotListener(usermetaSubID)
            }

            const usermetaSubscription = onSnapshot(doc(firestore_db, `usermeta/${gcid}`), async (snapshot) => {
                if (snapshot.exists()) {
                    const usermetaRecv = snapshot.data()

                    usermeta = {
                        ...usermetaRecv,
                        phoneNumber: {
                            phoneNumber: usermetaRecv?.phoneNumber?.phoneNumber || usermetaRecv.phoneNumber,
                            verified: usermetaRecv?.phoneNumber?.verified,
                        },
                        alertPhoneNumber: {
                            phoneNumber: usermetaRecv?.alertPhoneNumber?.phoneNumber || usermetaRecv.alertPhoneNumber,
                            verified: usermetaRecv?.alertPhoneNumber?.verified,
                        }
                    };

                    const { pageAuthorization } = await evaluatePermissions('all', {pageAuthorization: {roles}})

                    if (!pageAuthorization || !pageAuthorization.hasAccess) {
                        authToken = null
                        removeEventListener('beforeunload', beforeUnloadListener, {capture: true});
                        await signOut(firebase_auth)
                        return;
                    }
                } else {
                    window.location.href = '/contactSupport.html'
                    return;
                }
            })

            usermetaSubID = Listeners.addSnapshotListener(usermetaSubscription)
        }

        async function initAuthentication() {
            onAuthStateChanged(firebase_auth, async (user) => {
                if (user) {

                    if (authToken) {
                        const decodedAuthToken = decodeAuthToken(authToken);
                        if (decodedAuthToken) {
                            const {uid} = decodedAuthToken
                            const previousLoggedInGcid = localStorage.getItem('gcid');
                            if (reauth || (previousLoggedInGcid && previousLoggedInGcid !== uid)) {
                                reauth = false;
                                await signOut(firebase_auth)
                                return
                            }
                        }
                    }

                    // User is signed in.
                    loggedInUser = user;
                    const usermetaRef = await getDoc(doc(firestore_db, `usermeta/${user.uid}`))

                    if (!usermetaRef.exists() || !usermetaRef.data().tradingHouses || Object.keys(usermetaRef.data().tradingHouses).length === 0 ) {
                        authToken = null;
                        removeEventListener('beforeunload', beforeUnloadListener, {capture: true});
                        await signOut(firebase_auth)
                        return;
                    }

                    usermeta = {
                        ...usermetaRef.data(),
                        phoneNumber: {
                            phoneNumber: usermetaRef.data()?.phoneNumber?.phoneNumber || usermetaRef.data().phoneNumber,
                            verified: usermetaRef.data()?.phoneNumber?.verified,
                        },
                        alertPhoneNumber: {
                            phoneNumber: usermetaRef.data()?.alertPhoneNumber?.phoneNumber || usermetaRef.data().alertPhoneNumber,
                            verified: usermetaRef.data()?.alertPhoneNumber?.verified,
                        }
                    }

                    const tabId = makeID()
                    if (sessionStorage.getItem('browserID')) {
                        const nav = window.performance.getEntriesByType('navigation')
                        if (nav && nav[0]) {
                            // @ts-ignore
                            let navigationType = nav[0].type;
                            //back_forward value is duplicate tab
                            if(navigationType == 'back_forward') {
                                sessionStorage.setItem('browserID', tabId)
                            }
                        }
                    } else {
                        sessionStorage.setItem('browserID', tabId)
                    }

                    let tradingHouse = await getTradingHouseByDomain();
                    if (!tradingHouse) {
                        window.location.href = '/expiredLink.html'
                        return
                    }

                    // @ts-ignore
                    currentTradingHouse = tradingHouse

                    currentTradingHouse.routes = {
                        greenchart: currentTradingHouse.routes && currentTradingHouse.routes.greenchart ? currentTradingHouse.routes.greenchart : window.location.origin,
                        support: currentTradingHouse.routes && currentTradingHouse.routes.support ? currentTradingHouse.routes.support : '/contactSupport.html',
                        login: currentTradingHouse.routes && currentTradingHouse.routes.login ? currentTradingHouse.routes.login : '/contactSupport.html',
                        betaURL: currentTradingHouse.routes && currentTradingHouse.routes.betaURL ? currentTradingHouse.routes.betaURL : null,
                    }

                    if (currentTradingHouse.routes.betaURL
                        && window.location.origin === currentTradingHouse.routes.betaURL) {
                        // accessing beta site

                        if (!usermeta.isBetaTester) { // but if trader is NOT a betaTester switch to production
                            window.location.replace(`${currentTradingHouse.routes.greenchart}${preserveQueryParams()}`);
                        } else {  // otherwise, allow access to beta
                            window.history.pushState({}, document.title, `${currentTradingHouse.routes.betaURL}`);
                            isBeta = true
                        }
                    } else {
                        // accessing production
                        if (currentTradingHouse.routes.betaURL && usermeta.isBetaTester) { // but if trader IS a betaTester switch to beta site
                            window.location.replace(`${currentTradingHouse.routes.betaURL}${preserveQueryParams()}`);
                        } else { // otherwise, allow access to production
                            window.history.pushState({}, document.title, `${currentTradingHouse.routes.greenchart}`);
                            isBeta = false
                        }
                    }

                    const { pageAuthorization } = await evaluatePermissions('all', {pageAuthorization: {roles}})

                    if (!pageAuthorization || !pageAuthorization.hasAccess) {
                       authToken = null;
                       removeEventListener('beforeunload', beforeUnloadListener, {capture: true});
                       await signOut(firebase_auth)
                       window.location.href = currentTradingHouse.routes.support
                       return;
                   }


                    if (shouldRedirectToAccount) {
                        const createTokenCallable = httpsCallable(firebase_functions, 'createTokenCallable');

                        try {
                            const tokenFuncResponse = await createTokenCallable(usermeta.gcid);

                            let query = ''
                            if (marketplaceDisplayProduct) {
                                query += `&display=${marketplaceDisplayProduct}`
                            }
                            window.location.href = `${myAccountURl}?authToken=${tokenFuncResponse.data}${query}`
                            return;
                        } catch(err) {
                            console.error("ERROR CREATING TOKEN")
                        }
                    }

                    listenToAccountStatus(user.uid)

                    // Sentry.setUser({
                    //     id: usermeta.gcid,
                    //     email: usermeta.email
                    // })

                    callback()

                } else {
                    if (authToken) {
                        signIn()
                    } else {
                        const tradingHouse = await getTradingHouseByDomain();

                        if (tradingHouse) {
                            let redirectTo = location.origin
                            let queryParams = ""
                            if (tradingHouse.routes.login_redirect) {
                                redirectTo = tradingHouse.routes.login_redirect
                                queryParams = `?redirectTo=${redirectTo}`
                            }
                            window.location.href = `${tradingHouse.routes.login}${queryParams}${preserveQueryParams()}`
                        } else {
                           window.location.href = '/expiredLink.html'
                        }

                    }
                }
            });
        }

        async function getTradingHouseByName(tradingHouse?: string) {
            return getDoc(doc(firestore_db, `tradingHouses/${tradingHouse}`)).then(docSnapshot => docSnapshot.exists() ? docSnapshot.data() : null);
        }

        async function getTradingHouseByDomain() {
            return getDocs(
                query(
                    collection(firestore_db,'tradingHouses'),
                    where('domains', 'array-contains', location.origin),
                    limit(1))
            ).then((querySnapshot) => querySnapshot.docs.map(a => a.data())[0] )
        }

        function decodeAuthToken(authToken: string): any {
            try {
                const tokenDecodablePart = authToken.split('.')[1];

                return JSON.parse(Buffer.from(tokenDecodablePart, 'base64').toString())
            } catch (err) {
                return null
            }
        }

    }





}
