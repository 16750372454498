import { makeID } from './utils/utils';

export default {
  snapshotListeners: {},
  realtimeListeners: {},

  addSnapshotListener(snapshotListener) {
    let id;

    do {
      id = makeID();
    } while (this.snapshotListeners[id] || this.realtimeListeners[id])

    this.snapshotListeners[id] = snapshotListener
    return id;
  },

  addRealtimeListener(realtimeListener) {
    let id;

    do {
      id = makeID();
    } while (this.realtimeListeners[id] || this.snapshotListeners[id])

    this.realtimeListeners[id] = realtimeListener
    return id;
  },

  unsubscribeListener(id) {
    const snapshotListener = this.snapshotListeners[id]
    const realtimeListener = this.realtimeListeners[id]

    if (snapshotListener) {
      this.unsubscribeSnapshotListener(id)
    } else if (realtimeListener) {
      this.unsubscribeRealtimeListener(id)
    }
  },

  unsubscribeAllListeners() {
    this.unsubscribeSnapshotListeners();
    this.unsubscribeRealtimeListeners();
  },

  unsubscribeSnapshotListeners() {
    for(let snapshotID of Object.keys(this.snapshotListeners)) {
      this.unsubscribeSnapshotListener(snapshotID)
    }
  },

  unsubscribeRealtimeListeners() {
    for(let snapshotID of Object.keys(this.realtimeListeners)) {
      this.unsubscribeRealtimeListener(snapshotID)
    }
  },

  unsubscribeSnapshotListener(id) {
    const listener = this.snapshotListeners[id];
    if (listener) {
      listener();
      this.snapshotListeners[id] = null
      delete this.snapshotListeners[id]
    }
  },

  unsubscribeRealtimeListener(id) {
    const realtimeListener = this.realtimeListeners[id];
    if (realtimeListener) {
      realtimeListener.listener()
      this.realtimeListeners[id] = null
      delete this.realtimeListeners[id]
    }
  },
}
