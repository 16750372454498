import Auth from './core/auth'
const Navigo = require('navigo')
import "./core/components/Loading"
const routes = new Navigo('/')

routes.on('/', async () => {
    await Auth.init(async () => {
        const {GreenChartApp} = await import("./charting/app/GreenChartApp")
        const GreenChart = new GreenChartApp()
        document.body.append(GreenChart)
    })
})

routes.on('/tradeHistory.html', async () => {
    await Auth.init(async () => {
        const {TradeHistoryPage} = await import("./tradeHistory/tradeHistory");
        const TradeHistory = new TradeHistoryPage()
        document.body.append(TradeHistory)
    })
})

routes.on('/releaseNotes.html', async () => {
    const {ReleaseNotesPage} = await import("./releaseNotes/releaseNotes");
    const ReleaseNotes = new ReleaseNotesPage()
    document.body.append(ReleaseNotes)
})

routes.on('/patternCreation.html', async () => {
    await Auth.init(async () => {
        const {PatternCreation} = await import("./patternCreation/patternCreation");
        const patternCreation = new PatternCreation()
        document.body.append(patternCreation)
    }, ["admin"])
})

routes.resolve()
