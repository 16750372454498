import {customElement, state} from "lit/decorators.js";
import {html, LitElement} from "lit";


@customElement('gc-loading')
class Loading extends LitElement {

    @state()
    candles: {open: number, close: number, high: number, low: number}[] = []

    candleCount = 12;

    @state()
    timerSetInterval

    @state()
    updateCandleInterval = 150 //ms

    tickChangeAmount = 13; //px (price)
    maxTickChangeAmount = 15; //px (price)
    minTickChangeAmount = 8; //px (price)

    @state()
    maxHeight = 200; // max px

    @state()
    trend = 0

    candleWidth = 25;

    constructor() {
        super();

        for (let num = 0; num < this.candleCount; num++) {
            this.candles = this.candles.concat(this.createFakeCandle())
        }
    }

    connectedCallback() {
        super.connectedCallback()
        let time = 0
        this.timerSetInterval = setInterval(() => {
            time++;
            if (time > 5) {
                //create new candle
                this.candles = this.candles.slice(1);
                this.candles = [...this.candles, this.createNewCandle()]
                time = 0
            } else {
                this.updateCurrentCandle()
            }
        }, this.updateCandleInterval)
    }

    disconnectedCallback() {
        super.disconnectedCallback()
        clearInterval(this.timerSetInterval)
    }


    updateCurrentCandle() {

        let trendOdds = Math.ceil(Math.random()*10)
        let increase = trendOdds > (5 + this.trend);
        let currentCandle = this.candles[this.candles.length-1]

        let movement = this.maxTickChangeAmount
        if (this.trend === 1 || this.trend === -1) {
            movement = this.maxTickChangeAmount
        } else if (this.trend === 2 || this.trend === -2) {
            movement = this.minTickChangeAmount
        }
        currentCandle.close += (increase ? movement : -movement)


        if (currentCandle.close > this.maxHeight/2) {
            this.trend = -1
        } else {
            this.trend = 1
        }

        if (currentCandle.close > this.maxHeight-this.maxHeight*.35) {
            this.trend = 2
        } else if (currentCandle.close < this.maxHeight*.35) {
            this.trend = -2
        }

        currentCandle.close = Math.max(Math.min(currentCandle.close, this.maxHeight), 0)
        if (currentCandle.close > currentCandle.high) {
            currentCandle.high = currentCandle.close
        } else if (currentCandle.close < currentCandle.low) {
            currentCandle.low = currentCandle.close
        }

        this.candles = this.candles.slice()
    }

    createNewCandle() {
        let odds = Math.ceil(Math.random()*25)

        let prevCurrentCandle = this.candles[this.candles.length-1]
        let open = prevCurrentCandle ? prevCurrentCandle.close : odds;
        let close = open;
        let high = open;
        let low = open;

        return {open, high, low, close}
    }

    createFakeCandle() {
        const candle = this.createNewCandle();

        let candleInitialSizeDiff = 40;
        let priceDiff = Math.ceil(Math.random()*candleInitialSizeDiff)
        let highDiff = Math.ceil(Math.random()*5)
        let lowDiff = Math.ceil(Math.random()*5)

        if (priceDiff > candleInitialSizeDiff/2) {
            // green candle
            candle.close = Math.min(candle.open + priceDiff, this.maxHeight)
            candle.high = candle.close + highDiff
        } else {
            // red candle
            candle.close = Math.abs(candle.open - priceDiff)
            candle.low = Math.max(candle.close - lowDiff, 0)
        }

        return candle
    }

    render() {
        return html`
            <div style="display: flex; height: 100vh;">
<!--                <img src="css/img/GreenChart G + Text Stacked.png" height="200">-->

                <div class="container">
                    ${this.candles.map((candle, index) => html`<div 
                            class="candle ${candle.close > candle.open ? "red" : candle.close === candle.open ? "doji" : "green"}"
                            style="opacity: ${(index/this.candleCount ) + .05};height: ${Math.abs(candle.open - candle.close)}px; left: ${(this.candleWidth+8)*index+4}px; top: ${(candle.close > candle.open ? candle.open : candle.close)}px; display: flex; justify-content: center"
                    >
                        ${
                                candle.close > candle.open ? html`
                                <!--                            low on red -->
                                <div style="height: ${Math.abs(candle.open - candle.close)+Math.abs(candle.high-candle.close)}px; background: #810000; top: 0;" class="wick"></div>
                                <!--                            high on red -->
                                <div style="height: ${Math.abs(candle.open - candle.open)+Math.abs(candle.low-candle.close)}px; background: #810000; bottom: 0;" class="wick"></div>
                            ` : html`
                                <!--                            high on green -->
                                <div style="height: ${Math.abs(candle.open - candle.close)+Math.abs(candle.low-candle.close)}px; background: #008000; bottom: 0;" class="wick"></div>
                                <!--                            low on green -->
                                <div style="height: ${Math.abs(candle.open - candle.open)+Math.abs(candle.high-candle.close)}px; background: #008000; top: 0;" class="wick"></div>
                            `
                        }
                    </div>`)}

                    <div style="position: absolute; bottom: 0px; font-size: 15px; color: darkgrey">
                        LOADING CHARTS...
                    </div>
                </div>
            </div>
            
            
            <style>
                
                .container {
                    height: ${this.maxHeight}px;
                    width: ${((this.candleWidth+8) * this.candleCount)}px;
                    padding-bottom: 20px;
                    margin: auto;
                    position: relative;
                    overflow-y: hidden;
                }
            
                .candle {
                    width: ${this.candleWidth}px;
                    position: absolute;
                }
                
                .wick {
                    width: 2px;
                    position: absolute;
                    z-index: -1;
                }
                
                .doji {
                    border-bottom: 1px solid black;
                }
                
                .green {
                    background: #008000;
                }
                
                .red {
                    background: #810000;
                }
            </style>
        `
    }

}
